
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, UtGelDetails, UtGelItem } from "@/domain";

import { IUtGelService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class UtGelsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get utGelService() {
    return this.container.resolve<IUtGelService>(S.UT_GEL_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.gels, (s) => s.serialNumber);
  }

  get createComponent() {
    return () => import("@/components/Core/Create/Equipments/UtGelCreate.vue");
  }

  get updateComponent() {
    return () => import("@/components/Core/Update/Equipments/UtGelUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  gels: UtGelItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;

      this.gels = await this.utGelService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(gel: UtGelDetails) {
    this.gels.push(UtGelItem.fromDetails(gel));
  }

  update(gel: UtGelDetails) {
    const index = _.findIndex(this.gels, (g) => g.id == gel.id);
    if (index != -1) {
      this.gels.splice(index, 1, UtGelItem.fromDetails(gel));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const gel = _.find(this.gels, (g) => g.id == id)!;
      const result = await this.utGelService.update(id, {
        serialNumber: gel.serialNumber,
        equipmentType: gel.equipmentType,
        equipmentBrandId: gel.equipmentBrand.id,
        disabled: !gel.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
